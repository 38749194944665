import {
  AppBar,
  Box,
  IconButton,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationMenu from "./NotificationMenu";
import ProfileMenu from "./ProfileMenu";
import CountryMenu from "./CountryMenu";
import SettingsMenu from "./SettingsMenu";

const AppBarStyled = styled(AppBar)(({ theme, open, themeMode }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${240}px)`,
    marginLeft: `${240}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(localStorage.getItem("theme") === "dark" && {
    backgroundColor: theme.palette.background.paper,
  }),
}));

const NavBar = (props) => {
  const { open, openDrawerHandler } = props;

  return (
    <AppBarStyled position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={openDrawerHandler}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="overline"
          noWrap
          component="div"
          sx={{ fontSize: 22 }}
        >
          VLTTS
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <NotificationMenu />
        <SettingsMenu />
        <CountryMenu />
        <ProfileMenu />
      </Toolbar>
    </AppBarStyled>
  );
};

export default NavBar;
