import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useUsers } from "../../context/user-context";
import { useRoles } from "../../context/role-context";
import { useCompanies } from "../../context/company-context";
import { useModal } from "../../context/modal-context";

const UsersFilter = forwardRef((props, ref) => {
  const { modalState } = useModal();
  const { loading: usersLoading, searchUsers } = useUsers();
  const {
    companies,
    loading: companiesLoading,
    loadCompanies,
  } = useCompanies();
  const { roles, rolesLoading, loadRoles } = useRoles();
  const [selectedCompany, setSelectedCompany] = useState("all");
  const [selectedRole, setSelectedRole] = useState("all");

  useImperativeHandle(ref, () => ({
    resetFilter: () => {
      setSelectedCompany("all");
      setSelectedRole("all");
    },
  }));

  const roleChangeHandler = (value) => {
    setSelectedRole(value);
  };

  const companyChangeHandler = (value) => {
    setSelectedCompany(value);
  };

  const onSearchHandler = () => {
    const searchObj = {
      company_id: selectedCompany !== "all" ? selectedCompany : null,
      role_id: selectedRole !== "all" ? selectedRole : null,
    };
    searchUsers(searchObj);
  };

  useEffect(() => {
    loadCompanies();
    loadRoles();
    // eslint-disable-next-line
  }, []);

  const overallLoading =
    (rolesLoading || usersLoading || companiesLoading) && !modalState.open;

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={6} sm={4}>
        <FormControl fullWidth>
          <Autocomplete
            id="roleFilter"
            options={[{ id: "all", name: "All" }, ...roles]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", name: "All" }, ...roles].find(
                (role) => role.id === selectedRole
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: roles.length + 1 < 5,
                }}
                label="Role *"
              />
            )}
            getOptionLabel={(option) => option.show_name ?? option.name}
            onChange={(event, newOption) =>
              roleChangeHandler(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4}>
        <FormControl fullWidth>
          <Autocomplete
            id="companyFilter"
            options={[{ id: "all", groupation_name: "All" }, ...companies]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", groupation_name: "All" }, ...companies].find(
                (company) => company.id === selectedCompany
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: companies.length + 1 < 5,
                }}
                label="Company *"
              />
            )}
            getOptionLabel={(option) => option.groupation_name}
            onChange={(event, newOption) =>
              companyChangeHandler(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          variant="contained"
          sx={{ width: "100%", height: "100%" }}
          startIcon={!overallLoading && <SearchOutlined />}
          onClick={onSearchHandler}
          disabled={overallLoading}
        >
          {overallLoading ? <CircularProgress size={25} /> : "Search"}
        </Button>
      </Grid>
    </Grid>
  );
});

export default UsersFilter;
