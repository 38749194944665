import { Refresh } from "@mui/icons-material";
import { Container } from "@mui/material";
import { useRef, useMemo, useEffect } from "react";
import AllMachines from "../components/Machine/AllMachines";
import MachineModal from "../components/Machine/MachineModal";
import MachinesFilter from "../components/Machine/MachinesFilter";
import CRUDHeader from "../components/UI/CRUDHeader";
import { useCabinets } from "../context/cabinet-context";
import { useCompanies } from "../context/company-context";
import { useLocations } from "../context/location-context";
import { useMachines } from "../context/machine-context";
import { useModal } from "../context/modal-context";
import { useParts } from "../context/part-context";
import { useAuth } from "../context/auth-context";
import { hasAbility } from "../services/helpers";

const Machines = () => {
  const { clearMachinesData, machines } = useMachines();
  const { openModal } = useModal();
  const { loadCompanies } = useCompanies();
  const { loadLocations } = useLocations();
  const { loadCabinets } = useCabinets();
  const { loadParts, loadPartTypes } = useParts();
  const machinesFilterRef = useRef();
  const { currentUser } = useAuth();
  const canManageMachines = hasAbility(
    "can_manage_machine",
    currentUser.role.abilities
  );

  useEffect(() => {
    clearMachinesData();
    Promise.all([
      loadCompanies(),
      loadLocations(),
      loadCabinets(),
      loadParts(),
      loadPartTypes(),
    ]);
    // eslint-disable-next-line
  }, []);
  const onAddNew = canManageMachines
    ? () => openModal("add", "machine")
    : false;

  const onCustom = useMemo(() => {
    return [
      {
        label: "Reset Filters",
        color: "info",
        onClick: () => machinesFilterRef.current.resetFilter(),
        icon: <Refresh />,
        sx: { width: 120 },
      },
    ];
  }, []);

  return (
    <>
      <Container maxWidth="xl" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader title="Machines" onAddNew={onAddNew} onCustom={onCustom} />
        <MachinesFilter ref={machinesFilterRef} />
        {machines.length > 0 && (
          <AllMachines canManageMachines={canManageMachines} />
        )}
      </Container>
      <MachineModal />
    </>
  );
};

export default Machines;
