import React from "react";

import { Typography } from "@mui/material";
import dayjs from "dayjs";

const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      Copyright © VLTTS {dayjs().format("YYYY")}
    </Typography>
  );
};

export default Copyright;
