import {
  CheckOutlined,
  ClearOutlined,
  Close,
  DownloadOutlined,
  DriveFileRenameOutlineOutlined,
  HomeOutlined,
  LocalPhoneOutlined,
  LocationCityOutlined,
  MarkEmailReadOutlined,
  PinDropOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  DialogContentText,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { useCompanies } from "../../context/company-context";
import { useLocations } from "../../context/location-context";
import { useModal } from "../../context/modal-context";
import useInput from "../../hooks/use-input";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";
import NTSUploadButton from "../UI/NTSUploadButton";
import { getLocation } from "../../services/api-service";
import { useSnackbar } from "../../context/snackbar-context";

const LocationModal = () => {
  const { showSnackbar } = useSnackbar();
  const [innerLoading, setInnerLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const { modalState, closeModal } = useModal();
  const {
    companies,
    loading: companiesLoading,
    loadCompanies,
  } = useCompanies();
  const {
    locations,
    locationTypes,
    locationTypesLoading,
    loadLocationTypes,
    addLocation,
    editLocation,
    removeLocation,
  } = useLocations();

  const onUpload = (event) => {
    setImage(event.target.files[0]);
  };

  const {
    value: companyValue,
    isValid: companyIsValid,
    hasError: companyHasError,
    valueBlurHandler: companyBlurHandler,
    valueResetHandler: companyResetHandler,
    setValueHandler: setCompanyHandler,
  } = useInput((value) => value !== "");
  const {
    value: locationTypeValue,
    isValid: locationTypeIsValid,
    hasError: locationTypeHasError,
    valueBlurHandler: locationTypeBlurHandler,
    valueResetHandler: locationTypeResetHandler,
    setValueHandler: setLocationTypeHandler,
  } = useInput((value) => value !== "");
  const {
    value: nameValue,
    isValid: nameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangeHandler,
    valueBlurHandler: nameBlurHandler,
    valueResetHandler: nameResetHandler,
    setValueHandler: setNameHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    value: cityValue,
    isValid: cityIsValid,
    hasError: cityHasError,
    valueChangeHandler: cityChangeHandler,
    valueBlurHandler: cityBlurHandler,
    valueResetHandler: cityResetHandler,
    setValueHandler: setCityHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    value: addressValue,
    isValid: addressIsValid,
    hasError: addressHasError,
    valueChangeHandler: addressChangeHandler,
    valueBlurHandler: addressBlurHandler,
    valueResetHandler: addressResetHandler,
    setValueHandler: setAddressHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    value: phoneValue,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    valueResetHandler: phoneResetHandler,
    setValueHandler: setPhoneHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    value: mapLocationValue,
    hasError: mapLocationHasError,
    valueChangeHandler: mapLocationChangeHandler,
    valueResetHandler: mapLocationResetHandler,
    setValueHandler: setMapLocationHandler,
  } = useInput((value) => value.trim().length >= 3);
  const {
    value: zipValue,
    hasError: zipHasError,
    valueChangeHandler: zipChangeHandler,
    valueResetHandler: zipResetHandler,
    setValueHandler: setZipHandler,
  } = useInput((value) => value.trim().length >= 3);

  const resetForm = useCallback(() => {
    companyResetHandler();
    locationTypeResetHandler();
    nameResetHandler();
    cityResetHandler();
    addressResetHandler();
    phoneResetHandler();
    mapLocationResetHandler();
    zipResetHandler();
    setImage(null);
    setImageUrl("");
    // eslint-disable-next-line
  }, []);

  const setFields = useCallback((location) => {
    setCompanyHandler(location?.company?.id);
    setLocationTypeHandler(location?.location_type?.id);
    setNameHandler(location?.name);
    setCityHandler(location?.city);
    setAddressHandler(location?.address);
    setPhoneHandler(location?.phone);
    setMapLocationHandler(location?.map_location);
    setZipHandler(location?.zip);
    setImageUrl(location?.image);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (!modalState.type || modalState.model !== "location") return;
    loadCompanies();
    loadLocationTypes();
    if (modalState.type === "add") return;

    if (locations.length > 0) {
      const location = locations.find(
        (location) => location.id === modalState.id
      );
      if (location) setFields(location);
    } else if (modalState.id) {
      (async () => {
        setInnerLoading(true);
        try {
          const fetchedLocation = await getLocation(modalState.id);
          const location = fetchedLocation.data.data;
          if (location) setFields(location);
        } catch (error) {
          const errorMessage = error.response?.data?.message || error.message;
          showSnackbar(errorMessage, "error", "red");
        }
        setInnerLoading(false);
      })();
    }
    // eslint-disable-next-line
  }, [modalState]);

  const downloadImageHandler = async () => {
    const imageFullUrl = `${process.env.REACT_APP_IMAGES_URL}/locations/${imageUrl}`;
    saveAs(imageFullUrl);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const locationObj = {
      company_id: companyValue !== "" ? companyValue : null,
      location_type_id: locationTypeValue !== "" ? locationTypeValue : null,
      name: nameValue,
      city: cityValue,
      address: addressValue,
      zip: zipValue.trim().length !== 0 ? zipValue : null,
      phone: phoneValue.trim().length !== 0 ? phoneValue : null,
      map_location:
        mapLocationValue.trim().length !== 0 ? mapLocationValue : null,
      image,
    };
    if (modalState.type === "add") {
      addLocation(locationObj);
    }
    if (modalState.type === "edit") {
      editLocation(modalState.id, locationObj);
    }
    if (modalState.type === "delete") {
      removeLocation(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={
          !nameIsValid ||
          !cityIsValid ||
          !addressIsValid ||
          !companyIsValid ||
          !locationTypeIsValid
        }
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "location" && (
      <NTSModal
        title={`${title} LOCATION`}
        loading={companiesLoading || locationTypesLoading || innerLoading}
        loadingSx={{ marginBottom: 5 }}
        maxWidth="md"
      >
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove location ${nameValue}?`}</DialogContentText>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  id="company"
                  options={companies}
                  value={
                    companies.find((type) => type.id === companyValue) || null
                  }
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      {...params}
                      error={companyHasError}
                      inputProps={{
                        ...inputProps,
                        readOnly: companies.length < 5,
                      }}
                      label="Company *"
                    />
                  )}
                  getOptionLabel={(option) => option.groupation_name}
                  onChange={(event, newOption) =>
                    setCompanyHandler(newOption?.id ?? null)
                  }
                  onClose={companyBlurHandler}
                  disabled={modalState.type === "view"}
                  required
                  disableClearable
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  id="locationType"
                  options={locationTypes}
                  value={
                    locationTypes.find(
                      (type) => type.id === locationTypeValue
                    ) || null
                  }
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      {...params}
                      error={locationTypeHasError}
                      inputProps={{
                        ...inputProps,
                        readOnly: locationTypes.length < 5,
                      }}
                      label="Type *"
                    />
                  )}
                  getOptionLabel={(option) => option.show_name ?? option.name}
                  onChange={(event, newOption) =>
                    setLocationTypeHandler(newOption?.id ?? null)
                  }
                  onClose={locationTypeBlurHandler}
                  disabled={modalState.type === "view"}
                  required
                  disableClearable
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                disabled={modalState.type === "view"}
                value={nameValue}
                onChange={nameChangeHandler}
                onBlur={nameBlurHandler}
                error={nameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DriveFileRenameOutlineOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                disabled={modalState.type === "view"}
                value={addressValue}
                onChange={addressChangeHandler}
                onBlur={addressBlurHandler}
                error={addressHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="city"
                label="City"
                name="city"
                disabled={modalState.type === "view"}
                value={cityValue}
                onChange={cityChangeHandler}
                onBlur={cityBlurHandler}
                error={cityHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCityOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                margin="normal"
                fullWidth
                id="zip"
                label="Zip"
                name="zip"
                disabled={modalState.type === "view"}
                value={zipValue}
                onChange={zipChangeHandler}
                error={zipHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MarkEmailReadOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                id="mapLocation"
                label="Map Location"
                name="mapLocation"
                disabled={modalState.type === "view"}
                value={mapLocationValue}
                onChange={mapLocationChangeHandler}
                error={mapLocationHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PinDropOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                margin="normal"
                fullWidth
                id="phone"
                label="Phone"
                name="phone"
                disabled={modalState.type === "view"}
                value={phoneValue}
                onChange={phoneChangeHandler}
                error={phoneHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {modalState.type !== "view" ? (
                <NTSUploadButton
                  uploaded={image}
                  onUpload={onUpload}
                  buttonName="Upload Image"
                  modalType={modalState.type}
                />
              ) : imageUrl && imageUrl.trim().length > 0 ? (
                <Button
                  variant="outlined"
                  color="primary"
                  component="label"
                  onClick={downloadImageHandler}
                  startIcon={<DownloadOutlined />}
                  sx={{ width: "100%", height: "56px", marginTop: 1 }}
                >
                  Download Image
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  component="label"
                  disabled
                  sx={{ width: "100%", height: "56px", marginTop: 1 }}
                >
                  No Image
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default LocationModal;
