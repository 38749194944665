import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useCompanies } from "../../context/company-context";
import { useLocations } from "../../context/location-context";
import { useMachines } from "../../context/machine-context";
import { useServices } from "../../context/service-context";
import { useSnackbar } from "../../context/snackbar-context";
import { getTechnicians } from "../../services/api-service";
import { ServiceMachineTypes } from "../Service/ServiceMachineTypes";
import { ClosedStatuses } from "./ClosedStatuses";

const ClosedServicesFilter = forwardRef((props, ref) => {
  const { showSnackbar } = useSnackbar();
  const { searchClosedServices, loading: servicesLoading } = useServices();
  const { companies, loading: companiesLoading } = useCompanies();
  const { locations, locationsLoading } = useLocations();
  const { searchMachines, machines, loading: machinesLoading } = useMachines();

  const [dateFrom, setDateFrom] = useState(dayjs().subtract(1, "days"));
  const [dateTo, setDateTo] = useState(dayjs());
  const [selectedTehnician, setSelectedTechnician] = useState("all");
  const [selectedCloseStatus, setSelectedCloseStatus] = useState("All");
  const [selectedCompany, setSelectedCompany] = useState("all");
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [selectedType, setSelectedType] = useState("All");
  const [selectedMachine, setSelectedMachine] = useState("all");

  useEffect(() => {
    if (selectedLocation !== "all")
      searchMachines({ location_id: selectedLocation });
    // eslint-disable-next-line
  }, [selectedLocation]);

  const locationsForDisplay = useMemo(() => {
    let _locations = [...locations];
    if (selectedCompany !== "all")
      _locations = _locations.filter(
        (location) => location.company.id === selectedCompany
      );

    return _locations;
  }, [locations, selectedCompany]);

  const companiesForDisplay = useMemo(() => {
    let _companies = [...companies];
    if (selectedLocation !== "all") {
      const locationDetails = locationsForDisplay.find(
        (location) => location.id === selectedLocation
      );
      _companies = _companies.filter(
        (company) => company.id === locationDetails.company.id
      );
    }

    return _companies;
  }, [selectedLocation, companies, locationsForDisplay]);

  const machinesForDisplay = useMemo(() => {
    let _machines = [...machines];
    if (selectedType !== "All" && machines.length > 0)
      _machines = _machines.filter((machine) => machine.type === selectedType);

    return _machines;
  }, [machines, selectedType]);

  const [technicians, setTechnicians] = useState([]);
  const [techniciansLoading, setTechniciansLoading] = useState(false);
  const loadTechnicians = useCallback(async () => {
    setTechniciansLoading(true);
    try {
      const response = await getTechnicians();
      setTechnicians(response.data.data);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    setTechniciansLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadTechnicians();
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(ref, () => ({
    resetFilter: () => {
      setDateFrom(dayjs().subtract(1, "days"));
      setDateTo(dayjs());
      setSelectedTechnician("all");
      setSelectedCloseStatus("All");
      setSelectedCompany("all");
      setSelectedLocation("all");
      setSelectedType("All");
      setSelectedMachine("all");
    },
  }));

  const onSearchHandler = () => {
    const searchObj = {
      date_from: dateFrom,
      date_to: dateTo,
      technician_id: selectedTehnician !== "all" ? selectedTehnician : null,
      close_status: selectedCloseStatus !== "All" ? selectedCloseStatus : null,
      company_id: selectedCompany !== "all" ? selectedCompany : null,
      location_id: selectedLocation !== "all" ? selectedLocation : null,
      machine_id: selectedMachine !== "all" ? selectedMachine : null,
      type: selectedType !== "All" ? selectedType : null,
    };
    searchClosedServices(searchObj);
  };

  const overallLoading =
    companiesLoading ||
    locationsLoading ||
    machinesLoading ||
    techniciansLoading ||
    servicesLoading;

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={6} sm={4} md={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Date From"
            inputFormat="DD/MM/YYYY"
            value={dateFrom}
            onChange={(e) => setDateFrom(e)}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "100%" }} />
            )}
            disabled={overallLoading}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Date To"
            inputFormat="DD/MM/YYYY"
            value={dateTo}
            onChange={(e) => setDateTo(e)}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: "100%" }} />
            )}
            disabled={overallLoading}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="technician"
            options={[{ id: "all", fname: "All" }, ...technicians]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", fname: "All" }, ...technicians].find(
                (technician) => technician.id === selectedTehnician
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: technicians.length + 1 < 5,
                }}
                label="Tehnician"
              />
            )}
            getOptionLabel={(option) =>
              `${option.fname} ${option?.lname ?? ""} ${
                option?.lname ? `(${option?.role?.show_name})` : ""
              }`
            }
            onChange={(event, newOption) =>
              setSelectedTechnician(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="type"
            options={["All", ...ClosedStatuses]}
            value={
              ["All", ...ClosedStatuses].find(
                (type) => type === selectedCloseStatus
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: ClosedStatuses.length + 1 < 5,
                }}
                label="Close Status"
              />
            )}
            onChange={(event, newOption) => setSelectedCloseStatus(newOption)}
            disabled={overallLoading}
            disableClearable
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <FormControl fullWidth>
          <Autocomplete
            id="company"
            options={[
              { id: "all", groupation_name: "All" },
              ...companiesForDisplay,
            ]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [
                { id: "all", groupation_name: "All" },
                ...companiesForDisplay,
              ].find((company) => company.id === selectedCompany) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: companiesForDisplay.length + 1 < 5,
                }}
                label="Company"
              />
            )}
            getOptionLabel={(option) => option.groupation_name}
            onChange={(event, newOption) =>
              setSelectedCompany(newOption?.id ?? null)
            }
            disabled={overallLoading}
            disableClearable
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="location"
            options={[{ id: "all", show_name: "All" }, ...locationsForDisplay]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", show_name: "All" }, ...locationsForDisplay].find(
                (location) => location.id === selectedLocation
              ) || { id: "all", show_name: "All" }
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: locationsForDisplay.length + 1 < 5,
                }}
                label="Location"
              />
            )}
            getOptionLabel={(option) => option.show_name ?? option.name}
            onChange={(event, newOption) =>
              setSelectedLocation(newOption?.id ?? "all")
            }
            disabled={overallLoading}
            disableClearable
          />
        </FormControl>
      </Grid>

      <Grid item xs={6} sm={4} md={3}>
        <FormControl fullWidth>
          <Autocomplete
            id="machine"
            options={[
              { id: "all", machine_number: "All" },
              ...(selectedLocation === "all"
                ? [
                    {
                      id: "selectSpecific",
                      machine_number:
                        "Select a specific location to see machines",
                    },
                  ]
                : machinesForDisplay),
            ]}
            getOptionDisabled={(option) => option.id === "selectSpecific"}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [
                { id: "all", machine_number: "All" },
                ...(selectedLocation === "all"
                  ? [
                      {
                        id: "selectSpecific",
                        machine_number:
                          "Select a specific location to see machines",
                      },
                    ]
                  : machinesForDisplay),
              ].find((machine) => machine.id === selectedMachine) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                disabled={overallLoading}
                inputProps={{
                  ...inputProps,
                  readOnly: machinesForDisplay.length + 1 < 5,
                }}
                label="Machine"
              />
            )}
            getOptionLabel={(option) =>
              `${option.machine_number} ${
                option?.cabinet?.name
                  ? ` - ${option?.cabinet?.name} [${option?.serial_number}]`
                  : ""
              }`
            }
            onChange={(event, newOption) =>
              setSelectedMachine(newOption?.id ?? null)
            }
            disabled={overallLoading}
            disableClearable
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <FormControl fullWidth>
          <Autocomplete
            id="type"
            options={["All", ...ServiceMachineTypes]}
            value={
              ["All", ...ServiceMachineTypes].find(
                (type) => type === selectedType
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: ServiceMachineTypes.length + 1 < 5,
                }}
                label="Type"
              />
            )}
            onChange={(event, newOption) => setSelectedType(newOption)}
            disabled={overallLoading}
            disableClearable
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <Button
          variant="contained"
          sx={{ width: "100%", height: "100%" }}
          startIcon={!overallLoading && <SearchOutlined />}
          onClick={onSearchHandler}
          disabled={overallLoading}
        >
          {overallLoading ? <CircularProgress size={25} /> : "Search"}
        </Button>
      </Grid>
    </Grid>
  );
});

export default ClosedServicesFilter;
