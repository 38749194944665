import {
  CheckOutlined,
  ClearOutlined,
  PlaceOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogContentText,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useCompanies } from "../../context/company-context";
import { useLocations } from "../../context/location-context";
import { useModal } from "../../context/modal-context";
import { useSnackbar } from "../../context/snackbar-context";
import { useUsers } from "../../context/user-context";
import useInput from "../../hooks/use-input";
import {
  assignCompanyLocationsToUser,
  attachLocationsToUser,
  detachLocationsFromUser,
  detachUsersFromLocation,
} from "../../services/api-service";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";
import NTSMultiSelect from "../UI/NTSMultiSelect";

const AssignModal = (props) => {
  const { tableState, setTableState } = props;
  const { modalState, closeModal } = useModal();
  const { showSnackbar } = useSnackbar();
  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    users,
    loadUsersWithoutTechnicians,
    loading: usersLoading,
  } = useUsers();
  const { locations, loadLocations, locationsLoading } = useLocations();
  const {
    companies,
    loadCompanies,
    loading: companiesLoading,
  } = useCompanies();

  const [assignedLocations, setAssignedLocations] = useState([]);
  const {
    isValid: assignedLocationsIsValid,
    hasError: assignedLocationsError,
    valueBlurHandler: assignedLocationsBlurHandler,
    valueResetHandler: assignedLocationsResetHandler,
  } = useInput(() => assignedLocations.length > 0);
  const {
    value: userValue,
    isValid: userIsValid,
    hasError: userHasError,
    valueBlurHandler: userBlurHandler,
    valueResetHandler: userResetHandler,
    setValueHandler: setUserHandler,
  } = useInput((value) => value !== "");
  const {
    value: companyValue,
    isValid: companyIsValid,
    hasError: companyHasError,
    valueBlurHandler: companyBlurHandler,
    valueResetHandler: companyResetHandler,
    setValueHandler: setCompanyHandler,
  } = useInput((value) => value !== "");

  const resetForm = useCallback(() => {
    userResetHandler();
    assignedLocationsResetHandler();
    setAssignedLocations([]);
    companyResetHandler();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (!modalState.open || modalState.model !== "assigns") return;
    if (
      modalState.type !== "userToLocations" &&
      modalState.type !== "userToGroupation"
    ) {
      const model = tableState?.data?.find(
        (model) => model.id === modalState.id
      );
      setModel(model);
    } else {
      loadUsersWithoutTechnicians();
      if (modalState.type === "userToLocations") loadLocations();
      if (modalState.type === "userToGroupation") loadCompanies();
    }
    // eslint-disable-next-line
  }, [modalState]);

  const detachHandler = async () => {
    setLoading(true);
    try {
      const response =
        tableState.type === "User"
          ? await detachLocationsFromUser(tableState.parentId, {
              location_ids: [modalState.id],
            })
          : await detachUsersFromLocation(tableState.parentId, {
              user_ids: [modalState.id],
            });
      setTableState((prev) => ({
        ...prev,
        data: prev.data.filter((item) => item.id !== modalState.id),
      }));
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    setLoading(false);
    closeModal();
  };

  const assignLocationsHandler = async () => {
    setLoading(true);
    const locationIdsObj = {
      location_ids: assignedLocations.map((location) => location.id),
    };
    try {
      const response = await attachLocationsToUser(userValue, locationIdsObj);
      const message = response.data.message;
      if (tableState.parentId === userValue) {
        setTableState((prev) => ({
          ...prev,
          data: [...prev.data, ...assignedLocations],
        }));
      }
      showSnackbar(message, "success", "green");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    resetForm();
    setLoading(false);
    closeModal();
  };

  const assignGroupationHandler = async () => {
    setLoading(true);
    const companyIdObj = {
      company_id: companyValue,
    };
    try {
      const response = await assignCompanyLocationsToUser(
        userValue,
        companyIdObj
      );
      const message = response.data.message;
      showSnackbar(message, "success", "green");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    resetForm();
    setLoading(false);
    closeModal();
  };

  const submitHandler = () => {
    if (modalState.type === "userToLocations") assignLocationsHandler();
    if (modalState.type === "userToGroupation") assignGroupationHandler();
    if (
      modalState.type !== "userToLocations" &&
      modalState.type !== "userToGroupation"
    )
      detachHandler();
  };

  const title = useMemo(() => {
    switch (modalState.type) {
      case "userToLocations":
        return "Assign User to Locations";
      case "userToGroupation":
        return "Assign User to Groupation";
      default:
        return `REMOVE ${tableState.type === "User" ? "LOCATION" : "USER"}`;
    }
  }, [modalState.type, tableState.type]);

  return (
    modalState.open &&
    modalState.model === "assigns" && (
      <NTSModal
        title={title}
        loading={
          (modalState.type === "userToLocations" &&
            (usersLoading || locationsLoading)) ||
          (modalState.type === "userToGroupation" &&
            (usersLoading || companiesLoading))
        }
        loadingSx={{ marginBottom: 5 }}
        maxWidth={`${
          modalState.type !== "userToLocations" &&
          modalState.type !== "userToGroupation"
            ? "sm"
            : "md"
        }`}
      >
        {modalState.type !== "userToLocations" &&
        modalState.type !== "userToGroupation" ? (
          <DialogContentText>{`Are you sure that you want to remove ${
            model?.show_name ?? model?.name ?? `${model?.fname} ${model?.lname}`
          }?`}</DialogContentText>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  id="user"
                  options={users}
                  value={users.find((user) => user.id === userValue) || null}
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      {...params}
                      error={userHasError}
                      inputProps={{ ...inputProps, readOnly: users.length < 5 }}
                      label="User *"
                    />
                  )}
                  getOptionLabel={(option) => `${option.fname} ${option.lname}`}
                  onChange={(event, newOption) =>
                    setUserHandler(newOption?.id ?? null)
                  }
                  onClose={userBlurHandler}
                  disabled={usersLoading}
                  required
                  disableClearable
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={8}>
              {modalState.type === "userToLocations" && (
                <NTSMultiSelect
                  color="success"
                  disabled={locationsLoading}
                  assigns={assignedLocations}
                  onChange={setAssignedLocations}
                  onBlur={assignedLocationsBlurHandler}
                  required
                  error={assignedLocationsError}
                  values={locations}
                  icon={<PlaceOutlined />}
                  title="Locations *"
                />
              )}
              {modalState.type === "userToGroupation" && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    id="groupation"
                    options={companies}
                    value={
                      companies.find(
                        (company) => company.id === companyValue
                      ) || null
                    }
                    renderInput={({ inputProps, ...params }) => (
                      <TextField
                        {...params}
                        error={companyHasError}
                        inputProps={{
                          ...inputProps,
                          readOnly: companies.length > 5,
                        }}
                        label="Groupation *"
                      />
                    )}
                    getOptionLabel={(option) => option.groupation_name}
                    onChange={(event, newOption) =>
                      setCompanyHandler(newOption?.id ?? null)
                    }
                    onClose={companyBlurHandler}
                    disabled={companiesLoading}
                    required
                    disableClearable
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          <Button
            onClick={closeModal}
            endIcon={<ClearOutlined />}
            color="error"
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            endIcon={!loading && <CheckOutlined />}
            color="success"
            disabled={
              loading ||
              (modalState.type === "userToLocations" &&
                (!userIsValid || !assignedLocationsIsValid)) ||
              (modalState.type === "userToGroupation" &&
                (!userIsValid || !companyIsValid))
            }
          >
            {loading ? <CircularProgress size={25} /> : "Submit"}
          </Button>
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default AssignModal;
