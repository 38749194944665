import {
  Business,
  CheckOutlined,
  ClearOutlined,
  Close,
  Diversity2,
} from "@mui/icons-material";
import {
  Button,
  DialogContentText,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCompanies } from "../../context/company-context";
import { useModal } from "../../context/modal-context";
import useInput from "../../hooks/use-input";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";
import NTSUploadButton from "../UI/NTSUploadButton";

const CompanyModal = () => {
  const [memorandum, setMemorandum] = useState(null);
  const [memorandumURL, setMemorandumURL] = useState(null);
  const { modalState, closeModal } = useModal();
  const { companies, addCompany, editCompany, removeCompany } = useCompanies();

  const {
    value: groupationNameValue,
    isValid: groupationNameIsValid,
    hasError: groupationNameHasError,
    valueChangeHandler: groupationNameChangeHandler,
    valueBlurHandler: groupationNameBlurHandler,
    valueResetHandler: groupationNameResetHandler,
    setValueHandler: setGroupationNameHandler,
  } = useInput((value) => value.trim().length >= 5);
  const {
    value: companyNameValue,
    isValid: companyNameIsValid,
    hasError: companyNameHasError,
    valueChangeHandler: companyNameChangeHandler,
    valueBlurHandler: companyNameBlurHandler,
    valueResetHandler: companyNameResetHandler,
    setValueHandler: setCompanyNameHandler,
  } = useInput((value) => value.trim().length >= 5);

  const resetForm = useCallback(() => {
    groupationNameResetHandler();
    companyNameResetHandler();
    setMemorandum(null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (
      !modalState.type ||
      modalState.type === "add" ||
      modalState.model !== "company"
    )
      return;

    const company = companies.find((company) => company.id === modalState.id);
    setGroupationNameHandler(company?.groupation_name);
    setCompanyNameHandler(company?.company_name);
    const memorandumFullURL = `${process.env.REACT_APP_IMAGES_URL}/memorandums/${company?.memorandum}`;
    setMemorandumURL(memorandumFullURL);
    // eslint-disable-next-line
  }, [modalState]);

  const onUpload = (event) => {
    setMemorandum(event.target.files[0]);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const companyObj = {
      company_name: companyNameValue,
      groupation_name: groupationNameValue,
      memorandum,
    };
    if (modalState.type === "add") {
      addCompany(companyObj);
    }
    if (modalState.type === "edit") {
      editCompany(modalState.id, companyObj);
    }
    if (modalState.type === "delete") {
      removeCompany(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={
          !companyNameIsValid ||
          !groupationNameIsValid ||
          (!memorandum && modalState.type === "add")
        }
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "company" && (
      <NTSModal title={`${title} COMPANY`} maxWidth="sm">
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove company ${companyNameValue}?`}</DialogContentText>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Company name"
                name="name"
                disabled={modalState.type === "view"}
                value={companyNameValue}
                onChange={companyNameChangeHandler}
                onBlur={companyNameBlurHandler}
                error={companyNameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Groupation name"
                name="name"
                disabled={modalState.type === "view"}
                value={groupationNameValue}
                onChange={groupationNameChangeHandler}
                onBlur={groupationNameBlurHandler}
                error={groupationNameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Diversity2 />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {modalState.type !== "view" ? (
                <NTSUploadButton
                  required={modalState.type === "add"}
                  uploaded={memorandum}
                  onUpload={onUpload}
                  buttonName="Upload Memorandum"
                  modalType={modalState.type}
                  description="*Upload new image ONLY if you want to change the current
                      one"
                />
              ) : (
                <img
                  src={memorandumURL}
                  alt="memorandum"
                  style={{ maxHeight: "100px", maxWidth: "inherit" }}
                />
              )}
            </Grid>
          </Grid>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default CompanyModal;
