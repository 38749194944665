import { Grid, List, Paper, Tab, Tabs } from "@mui/material";
import { useState, useEffect } from "react";
import { useAuth } from "../../context/auth-context";
import { useServices } from "../../context/service-context";
import { isInRoleTypes } from "../../services/helpers";
import LinearLoad from "../UI/LinearLoad";
import ActionsTabContent from "./ServiceBoxTabs/ActionsTabContent";
import DetailsTabContent from "./ServiceBoxTabs/DetailsTabContent";
import TimelineTabContent from "./ServiceBoxTabs/TimelineTabContent";
import useCheckIsMobile from "../../hooks/use-check-is-mobile";

const ServiceBox = (props) => {
  const { service } = props;
  const { editService } = useServices();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("1");
  const isMobile = useCheckIsMobile();

  const editServiceHandler = async (request) => {
    setLoading(true);
    await editService(service.id, request);
    setLoading(false);
    setValue("1");
  };

  useEffect(() => {
    setValue("1");
  }, []);

  return (
    <Grid item xs={12} sm={6}>
      <Paper
        sx={{
          overflow: "auto",
          minHeight: "375px",
          maxHeight: isMobile ? "630px" : "375px",
        }}
      >
        <Tabs
          value={value}
          onChange={(e, value) => setValue(value)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Details" value="1" />
          <Tab label="Timeline" value="2" />
          {((isInRoleTypes(currentUser.role.role_type.name, [
            "admin",
            "country_admin",
            "operations",
          ]) &&
            service.step === "Closed") ||
            (isInRoleTypes(currentUser.role.role_type.name, [
              "admin",
              "service",
            ]) &&
              service.step !== "Closed")) && <Tab label="Actions" value="3" />}
        </Tabs>
        {loading && <LinearLoad />}
        <List dense sx={{ paddingBottom: 0, height: "87.3%" }}>
          {(() => {
            switch (value) {
              case "1":
                return <DetailsTabContent service={service} />;
              case "2":
                return (
                  <TimelineTabContent
                    serviceId={service.id}
                    serviceConversation={service.service_conversation}
                  />
                );
              case "3":
                return (
                  <ActionsTabContent
                    serviceId={service.id}
                    serviceStep={service.step}
                    errorDescription={service.error_description}
                    onEditService={editServiceHandler}
                    loading={loading}
                  />
                );
              default:
                return <></>;
            }
          })()}
        </List>
      </Paper>
    </Grid>
  );
};

export default ServiceBox;
