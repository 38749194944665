import {
  BubbleChartOutlined,
  CheckOutlined,
  ClearOutlined,
  GroupWorkOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useLocations } from "../../context/location-context";
import { useModal } from "../../context/modal-context";
import { useSpareParts } from "../../context/spare-part-context";
import useInput from "../../hooks/use-input";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";
import NTSMultiSelect from "../UI/NTSMultiSelect";

const WarehouseModal = () => {
  const { modalState, closeModal } = useModal();
  const {
    warehouses,
    addSparePartsToWarehouse,
    transferSparePartsBetweenWarehouses,
  } = useLocations();
  const {
    loadSpareParts,
    loading: sparePartsLoading,
    spareParts,
  } = useSpareParts();

  const [assignedSpareParts, setAssignedSpareParts] = useState([]);
  const {
    value: quantityValue,
    isValid: quantityIsValid,
    hasError: quantityHasError,
    valueChangeHandler: quantityChangeHandler,
    valueBlurHandler: quantityBlurHandler,
    valueResetHandler: quantityResetHandler,
    setValueHandler: setQuantityHandler,
  } = useInput((value) => parseInt(value) > 0);
  const {
    value: warehouseFromValue,
    isValid: warehouseFromIsValid,
    hasError: warehouseFromHasError,
    valueBlurHandler: warehouseFromBlurHandler,
    valueResetHandler: warehouseFromResetHandler,
    setValueHandler: setWarehouseFromHandler,
  } = useInput((value) => value !== "");
  const {
    value: warehouseToValue,
    isValid: warehouseToIsValid,
    hasError: warehouseToHasError,
    valueBlurHandler: warehouseToBlurHandler,
    valueResetHandler: warehouseToResetHandler,
    setValueHandler: setWarehouseToHandler,
  } = useInput((value) => value !== "");

  const resetForm = useCallback(() => {
    setAssignedSpareParts([]);
    quantityResetHandler(1);
    warehouseFromResetHandler();
    warehouseToResetHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (!modalState.type) return;
    loadSpareParts();
    setQuantityHandler(1);
    // eslint-disable-next-line
  }, [modalState]);

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    let assignObj = {
      spare_part_ids: assignedSpareParts.map((sparePart) => sparePart.id),
      quantity: quantityValue,
    };
    if (modalState.type === "add") {
      addSparePartsToWarehouse(modalState.id, assignObj);
    }
    if (modalState.type === "transfer") {
      assignObj = {
        ...assignObj,
        warehouse_from: warehouseFromValue,
        warehouse_to: warehouseToValue,
      };
      transferSparePartsBetweenWarehouses(assignObj);
    }
    resetForm();
  };

  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={
          !quantityIsValid ||
          assignedSpareParts.length < 1 ||
          (modalState.type === "transfer" && !warehouseFromIsValid) ||
          (modalState.type === "transfer" && !warehouseToIsValid)
        }
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  return (
    modalState.open && (
      <NTSModal
        title={`${
          modalState.type === "add"
            ? `Add Spare Part to Warehouse: ${
                warehouses.find((warehouse) => warehouse.id === modalState.id)
                  .name
              }`
            : "Transfer Parts between Warehouses"
        }`}
        maxWidth="md"
        loading={sparePartsLoading}
        loadingSx={{ marginBottom: 5 }}
      >
        <Grid container spacing={2}>
          {modalState.type === "transfer" && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    id="warehouse_from"
                    options={warehouses}
                    renderInput={({ inputProps, ...params }) => (
                      <TextField
                        {...params}
                        error={warehouseFromHasError}
                        inputProps={{
                          ...inputProps,
                          readOnly: warehouses.length < 5,
                        }}
                        label="Warehouse From *"
                      />
                    )}
                    getOptionLabel={(option) => option.show_name ?? option.name}
                    onChange={(event, newOption) =>
                      setWarehouseFromHandler(newOption?.id ?? null)
                    }
                    onClose={warehouseFromBlurHandler}
                    required
                    disableClearable
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    id="warehouse_to"
                    options={warehouses}
                    renderInput={({ inputProps, ...params }) => (
                      <TextField
                        {...params}
                        error={warehouseToHasError}
                        inputProps={{
                          ...inputProps,
                          readOnly: warehouses.length < 5,
                        }}
                        label="Warehouse To *"
                      />
                    )}
                    getOptionLabel={(option) => option.show_name ?? option.name}
                    onChange={(event, newOption) =>
                      setWarehouseToHandler(newOption?.id ?? null)
                    }
                    onClose={warehouseToBlurHandler}
                    required
                    disableClearable
                  />
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={10}>
            <NTSMultiSelect
              color="success"
              loading={sparePartsLoading}
              assigns={assignedSpareParts}
              onChange={setAssignedSpareParts}
              values={spareParts}
              icon={<GroupWorkOutlined />}
              title="Spare Parts"
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="quantity"
              label="Quantity"
              name="quantity"
              type="number"
              value={quantityValue}
              onChange={quantityChangeHandler}
              onBlur={quantityBlurHandler}
              error={quantityHasError}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BubbleChartOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default WarehouseModal;
