import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import Dashboard from "../../features/Dashboard";
import { hasAbility, isInRoleTypes } from "../../services/helpers";
import Countries from "../../features/Countries";
import LinearLoad from "../UI/LinearLoad";
import Companies from "../../features/Companies";
import Roles from "../../features/Roles";
import Users from "../../features/Users";
import Locations from "../../features/Locations";
import Assigns from "../../features/Assigns";
import Groups from "../../features/Groups";
import Manufacturers from "../../features/Manufacturers";
import Cabinets from "../../features/Cabinets";
import PartTypes from "../../features/PartTypes";
import Parts from "../../features/Parts";
import DeviceTypes from "../../features/DeviceTypes";
import Devices from "../../features/Devices";
import Machines from "../../features/Machines";
import SpareParts from "../../features/SpareParts";
import Warehouses from "../../features/Warehouses";
import Services from "../../features/Services";
import ServiceHistory from "../../features/ServiceHistory";
import MachineChanges from "../../features/MachineChanges";

const MainRoutes = () => {
  const { currentUser } = useAuth();

  return (
    <Suspense fallback={<LinearLoad />}>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        {isInRoleTypes(currentUser.role.role_type.name, ["admin"]) && (
          <Route path="/countries" element={<Countries />} />
        )}
        {isInRoleTypes(currentUser.role.role_type.name, [
          "admin",
          "country_admin",
        ]) && (
          <>
            <Route path="/roles" element={<Roles />} />
            <Route path="/companies" element={<Companies />} />
          </>
        )}
        {hasAbility("can_manage_user", currentUser.role.abilities) && (
          <Route path="/users" element={<Users />} />
        )}
        {hasAbility("can_assign_user", currentUser.role.abilities) && (
          <Route path="/assigns" element={<Assigns />} />
        )}
        {hasAbility("can_view_location", currentUser.role.abilities) && (
          <Route path="/locations" element={<Locations />} />
        )}
        {hasAbility("can_view_group", currentUser.role.abilities) && (
          <Route path="/groups" element={<Groups />} />
        )}
        {hasAbility("can_manage_manufacturer", currentUser.role.abilities) && (
          <Route path="/manufacturers" element={<Manufacturers />} />
        )}
        {hasAbility("can_manage_cabinet", currentUser.role.abilities) && (
          <Route path="/cabinets" element={<Cabinets />} />
        )}
        {hasAbility("can_manage_part_type", currentUser.role.abilities) && (
          <Route path="/part-types" element={<PartTypes />} />
        )}
        {hasAbility("can_manage_part", currentUser.role.abilities) && (
          <Route path="/parts" element={<Parts />} />
        )}
        {hasAbility("can_manage_device_type", currentUser.role.abilities) && (
          <Route path="/device-types" element={<DeviceTypes />} />
        )}
        {hasAbility("can_manage_device", currentUser.role.abilities) && (
          <Route path="/devices" element={<Devices />} />
        )}
        {hasAbility("can_view_machine", currentUser.role.abilities) && (
          <Route path="/machines" element={<Machines />} />
        )}
        {hasAbility("can_manage_machine", currentUser.role.abilities) && (
          <Route path="/machine-changes" element={<MachineChanges />} />
        )}
        {hasAbility("can_manage_spare_part", currentUser.role.abilities) && (
          <>
            <Route path="/spare-parts" element={<SpareParts />} />
            <Route path="/operate-warehouses" element={<Warehouses />} />
          </>
        )}
        {hasAbility("can_manage_service", currentUser.role.abilities) && (
          <Route path="/service-manager" element={<Services />} />
        )}
        {hasAbility("can_view_service_history", currentUser.role.abilities) && (
          <Route path="/service-history" element={<ServiceHistory />} />
        )}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
