import {
  CheckOutlined,
  ClearOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  Switch,
  TextField,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useLocations } from "../../context/location-context";
import { useMachines } from "../../context/machine-context";
import { useModal } from "../../context/modal-context";
import { useServices } from "../../context/service-context";
import { getTitleForModal } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import { MachineTypes } from "../Machine/MachineTypes";
import { ServiceMachineTypes } from "./ServiceMachineTypes";
import NTSUploadButton from "../UI/NTSUploadButton";
import NTSModalFooter from "../UI/NTSModalFooter";
import useInput from "../../hooks/use-input";

const ServiceModal = () => {
  const { modalState, closeModal } = useModal();
  const { addService } = useServices();
  const { locations, locationsLoading, searchLocations } = useLocations();
  const {
    machines,
    loading: machinesLoading,
    searchMachines,
    clearMachinesData,
  } = useMachines();
  const [showPictureField, setShowPictureField] = useState(false);
  const [picture, setPicture] = useState(null);
  const [urgent, setUrgent] = useState(false);
  const {
    value: locationValue,
    isValid: locationIsValid,
    hasError: locationHasError,
    valueBlurHandler: locationBlurHandler,
    valueResetHandler: locationResetHandler,
    setValueHandler: setLocationHandler,
  } = useInput((value) => value !== "");
  const {
    value: typeValue,
    isValid: typeIsValid,
    hasError: typeHasError,
    valueBlurHandler: typeBlurHandler,
    valueResetHandler: typeResetHandler,
    setValueHandler: setTypeHandler,
  } = useInput((value) => value !== "");
  const {
    value: machineValue,
    isValid: machineIsValid,
    hasError: machineHasError,
    valueBlurHandler: machineBlurHandler,
    valueResetHandler: machineResetHandler,
    setValueHandler: setMachineHandler,
  } = useInput((value) => value !== "");
  const {
    value: errorDescriptionValue,
    isValid: errorDescriptionIsValid,
    hasError: errorDescriptionHasError,
    valueChangeHandler: errorDescriptionChangeHandler,
    valueBlurHandler: errorDescriptionBlurHandler,
    valueResetHandler: errorDescriptionResetHandler,
  } = useInput((value) => value.trim().length >= 5);

  const resetForm = useCallback(() => {
    setUrgent(false);
    setPicture(null);
    typeResetHandler();
    locationResetHandler();
    machineResetHandler();
    errorDescriptionResetHandler();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (!modalState.type || modalState.model !== "service") return;
    clearMachinesData();
    searchLocations({ location_type_id: 1 });
    if (modalState.type === "add") return;
    // eslint-disable-next-line
  }, [modalState]);

  const onUpload = (event) => {
    setPicture(event.target.files[0]);
  };

  const locationChangeHandler = (e, newOption) => {
    if (MachineTypes.includes(typeValue) && !!newOption?.id) {
      searchMachines({ type: typeValue, location_id: newOption?.id });
      machineResetHandler();
    }
    setLocationHandler(newOption?.id ?? null);
  };

  const serviceMachineTypesChangeHandler = (e, newOption) => {
    if (MachineTypes.includes(newOption) && !!locationValue) {
      searchMachines({ type: newOption, location_id: locationValue });
      machineResetHandler();
    }
    setTypeHandler(newOption ?? null);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const serviceObj = {
      location_id: locationValue,
      machine_type: typeValue,
      machine_id: !!machineValue ? machineValue : null,
      error_description: errorDescriptionValue,
      urgent: !!urgent ? 1 : null,
      picture,
    };
    if (modalState.type === "add") {
      addService(serviceObj);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={
          !locationIsValid ||
          !errorDescriptionIsValid ||
          !typeIsValid ||
          (MachineTypes.includes(typeValue) && !machineIsValid)
        }
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }

  return (
    modalState.open &&
    modalState.model === "service" && (
      <NTSModal
        title={`${title} SERVICE`}
        loading={locationsLoading}
        loadingSx={{ marginBottom: 5 }}
        maxWidth="md"
      >
        {
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  id="location"
                  options={locations}
                  value={
                    locations.find(
                      (location) => location.id === locationValue
                    ) || null
                  }
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      {...params}
                      error={locationHasError}
                      inputProps={{
                        ...inputProps,
                        readOnly: locations.length < 5,
                      }}
                      disabled={locationsLoading}
                      label="Location *"
                    />
                  )}
                  getOptionLabel={(option) => option.show_name ?? option.name}
                  onChange={locationChangeHandler}
                  onClose={locationBlurHandler}
                  disabled={locationsLoading}
                  required
                  disableClearable
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  id="machine_type"
                  options={ServiceMachineTypes}
                  value={
                    ServiceMachineTypes.find((type) => type === typeValue) ||
                    null
                  }
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      {...params}
                      error={typeHasError}
                      inputProps={{
                        ...inputProps,
                        readOnly: ServiceMachineTypes.length < 5,
                      }}
                      label="Type *"
                    />
                  )}
                  onChange={serviceMachineTypesChangeHandler}
                  onClose={typeBlurHandler}
                  required
                  disableClearable
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
              <FormControl
                fullWidth
                sx={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  m: 0,
                }}
              >
                <FormControlLabel
                  label="Urgent"
                  sx={{ mt: 2 }}
                  control={
                    <Switch
                      checked={urgent}
                      onChange={(e, newValue) => setUrgent(!!newValue)}
                    />
                  }
                />
              </FormControl>
            </Grid>
            {MachineTypes.includes(typeValue) && !!locationValue && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    id="machine"
                    options={machines}
                    value={
                      machines.find((machine) => machine.id === machineValue) ||
                      null
                    }
                    renderInput={({ inputProps, ...params }) => (
                      <TextField
                        {...params}
                        error={machineHasError}
                        disabled={machinesLoading}
                        inputProps={{
                          ...inputProps,
                          readOnly: machines.length < 5,
                        }}
                        label="Machine *"
                      />
                    )}
                    getOptionLabel={(option) =>
                      `${option.machine_number} - ${option.cabinet.name} [${option.serial_number}]`
                    }
                    onChange={(event, newOption) =>
                      setMachineHandler(newOption?.id ?? null)
                    }
                    onClose={machineBlurHandler}
                    disabled={machinesLoading}
                    required
                    disableClearable
                  />
                </FormControl>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={MachineTypes.includes(typeValue) && !!locationValue ? 6 : 12}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="error_description"
                label="Service Description"
                name="error_description"
                value={errorDescriptionValue}
                onChange={errorDescriptionChangeHandler}
                onBlur={errorDescriptionBlurHandler}
                error={errorDescriptionHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ErrorOutlineOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              {!showPictureField ? (
                <Link
                  underline="hover"
                  variant="body2"
                  href="#"
                  onClick={() => setShowPictureField(true)}
                >
                  Do you want to add a picture?
                </Link>
              ) : (
                <NTSUploadButton
                  uploaded={picture}
                  onUpload={onUpload}
                  buttonName="Upload Picture"
                />
              )}
            </Grid>
          </Grid>
        }
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default ServiceModal;
