import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useLocations } from "../../context/location-context";
import { useModal } from "../../context/modal-context";
import { useSnackbar } from "../../context/snackbar-context";
import { useUsers } from "../../context/user-context";
import useInput from "../../hooks/use-input";
import {
  getLocationsByUser,
  getUsersByLocation,
} from "../../services/api-service";

const types = ["User", "Location"];

const SearchAssigns = (props) => {
  const { loading, setLoading } = props;
  const [type, setType] = useState("User");
  const { showSnackbar } = useSnackbar();
  const { modalState } = useModal();
  const { users, loadUsers, loading: usersLoading } = useUsers();
  const { locations, loadLocations, locationsLoading } = useLocations();
  const {
    value: userValue,
    isValid: userIsValid,
    hasError: userHasError,
    valueBlurHandler: userBlurHandler,
    valueResetHandler: userResetHandler,
    setValueHandler: setUserHandler,
  } = useInput((value) => value !== "" || type !== "User");
  const {
    value: locationValue,
    isValid: locationIsValid,
    hasError: locationHasError,
    valueBlurHandler: locationBlurHandler,
    valueResetHandler: locationResetHandler,
    setValueHandler: setLocationHandler,
  } = useInput((value) => value !== "" || type !== "Location");

  const resetForm = useCallback(() => {
    userResetHandler();
    locationResetHandler();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (type === "User") {
      loadUsers();
    } else {
      loadLocations();
    }
    // eslint-disable-next-line
  }, [type]);

  const searchHandler = async () => {
    setLoading(true);
    try {
      if (type === "User") {
        const response = await getLocationsByUser({ user_id: userValue });
        props.setTableState((prevState) => ({
          type: type,
          data: response.data.data,
          parentId: userValue,
        }));
        if (response.data.data.length < 1)
          showSnackbar(
            "No results found according to search criteria",
            "warning",
            "orange"
          );
      } else {
        const response = await getUsersByLocation({
          location_id: locationValue,
        });
        props.setTableState((prevState) => ({
          type: type,
          data: response.data.data,
          parentId: locationValue,
        }));
        if (response.data.data.length < 1)
          showSnackbar(
            "No results found according to search criteria",
            "warning",
            "orange"
          );
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      showSnackbar(errorMessage, "error", "red");
    }
    setLoading(false);
  };

  const overallLoading =
    (usersLoading || locationsLoading || loading) && !modalState.open;

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={6} sm={4}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Autocomplete
            id="type"
            options={types}
            value={types.find((item) => item === type)}
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{ ...inputProps, readOnly: types.length < 5 }}
                label="Select Type *"
              />
            )}
            onChange={(event, newOption) => {
              props.setTableState({ type: null, data: null, parentId: null });
              setType(newOption);
            }}
            disabled={overallLoading}
            required
            disableClearable
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          {type === "User" ? (
            <Autocomplete
              id="user"
              options={users}
              value={users.find((user) => user.id === userValue) || null}
              renderInput={({ inputProps, ...params }) => (
                <TextField
                  {...params}
                  error={userHasError}
                  inputProps={{ ...inputProps, readOnly: users.length < 5 }}
                  label="Select User *"
                />
              )}
              getOptionLabel={(option) => `${option.fname} ${option.lname}`}
              onChange={(event, newOption) => {
                setUserHandler(newOption?.id ?? null);
              }}
              onClose={userBlurHandler}
              disabled={overallLoading}
              required
              disableClearable
            />
          ) : (
            <Autocomplete
              id="location"
              options={locations}
              value={
                locations.find((location) => location.id === locationValue) ||
                null
              }
              renderInput={({ inputProps, ...params }) => (
                <TextField
                  {...params}
                  error={locationHasError}
                  inputProps={{ ...inputProps, readOnly: locations.length < 5 }}
                  label="Select Location *"
                />
              )}
              getOptionLabel={(option) => option.show_name ?? option.name}
              onChange={(event, newOption) =>
                setLocationHandler(newOption?.id ?? null)
              }
              onClose={locationBlurHandler}
              disabled={overallLoading}
              required
              disableClearable
            />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button
          onClick={searchHandler}
          disabled={!locationIsValid || !userIsValid || overallLoading}
          startIcon={overallLoading ? "" : <SearchOutlined />}
          variant="contained"
          color="info"
          fullWidth
          sx={{ height: "56px", mt: 2 }}
        >
          {overallLoading ? <CircularProgress size={25} /> : "Search"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchAssigns;
