import {
  AdfScannerOutlined,
  AssessmentOutlined,
  ChevronLeft,
  DashboardOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  FactoryOutlined,
  GamesOutlined,
  GroupWorkOutlined,
  HomeRepairService,
  PlaceOutlined,
  RouterOutlined,
  SmartScreenOutlined,
  TonalityOutlined,
  TripOriginOutlined,
  WarehouseOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import useCheckIsMobile from "../../hooks/use-check-is-mobile";
import { hasAbility } from "../../services/helpers";

const CustomDrawerItem = (props) => {
  const theme = useTheme();
  const { text, icon, additional, ...rest } = props;

  return (
    <ListItem
      component={Button}
      key={text}
      {...rest}
      sx={{ textTransform: "none", color: theme.palette.text.primary }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
      {additional}
    </ListItem>
  );
};

const DrawerHeader = styled(Box)(({ theme }) => ({
  margin: 0,
  display: "flex",
  alignItems: "center",
  padding: `${0} ${1}`,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBar = (props) => {
  const { open, closeDrawerHandler } = props;
  const { currentUser } = useAuth();
  const [machinesSubmenuOpen, setMachinesSubmenuOpen] = useState(false);
  const [locationsSubmenuOpen, setLocationsSubmenuOpen] = useState(false);
  const [reportsSubmenuOpen, setReportsSubmenuOpen] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  const isMobile = useCheckIsMobile();

  const isActive = (path) => {
    return path === location.pathname;
  };

  const menuClickHandler = (item) => {
    if (isMobile) closeDrawerHandler();
    navigate(`/${item}`);
  };

  const machinesDropdownMenu = useMemo(() => {
    let machinesDropdownMenu = [];
    if (hasAbility("can_view_machine", currentUser.role.abilities)) {
      machinesDropdownMenu = [
        ...machinesDropdownMenu,
        {
          icon: <GamesOutlined color="primary" />,
          text: "Machines",
          path: "/machines",
          onClick: () => menuClickHandler("machines"),
        },
      ];
    }
    if (hasAbility("can_manage_manufacturer", currentUser.role.abilities)) {
      machinesDropdownMenu = [
        ...machinesDropdownMenu,
        {
          icon: <FactoryOutlined color="secondary" />,
          text: "Manufacturers",
          path: "/manufacturers",
          onClick: () => menuClickHandler("manufacturers"),
        },
      ];
    }
    if (hasAbility("can_manage_cabinet", currentUser.role.abilities)) {
      machinesDropdownMenu = [
        ...machinesDropdownMenu,
        {
          icon: <AdfScannerOutlined color="secondary" />,
          text: "Cabinets",
          path: "/cabinets",
          onClick: () => menuClickHandler("cabinets"),
        },
      ];
    }
    if (hasAbility("can_manage_part_type", currentUser.role.abilities)) {
      machinesDropdownMenu = [
        ...machinesDropdownMenu,
        {
          icon: <GroupWorkOutlined color="success" />,
          text: "Part Types",
          path: "/part-types",
          onClick: () => menuClickHandler("part-types"),
        },
      ];
    }
    if (hasAbility("can_manage_spare_part", currentUser.role.abilities)) {
      machinesDropdownMenu = [
        ...machinesDropdownMenu,
        {
          icon: <TonalityOutlined color="success" />,
          text: "Spare Parts",
          path: "/spare-parts",
          onClick: () => menuClickHandler("spare-parts"),
        },
      ];
    }
    if (hasAbility("can_manage_part", currentUser.role.abilities)) {
      machinesDropdownMenu = [
        ...machinesDropdownMenu,
        {
          icon: <TripOriginOutlined color="success" />,
          text: "Parts",
          path: "/parts",
          onClick: () => menuClickHandler("parts"),
        },
      ];
    }
    if (hasAbility("can_manage_device_type", currentUser.role.abilities)) {
      machinesDropdownMenu = [
        ...machinesDropdownMenu,
        {
          icon: <RouterOutlined color="warning" />,
          text: "Device Types",
          path: "/device-types",
          onClick: () => menuClickHandler("device-types"),
        },
      ];
    }
    if (hasAbility("can_manage_device", currentUser.role.abilities)) {
      machinesDropdownMenu = [
        ...machinesDropdownMenu,
        {
          icon: <SmartScreenOutlined color="warning" />,
          text: "Devices",
          path: "/devices",
          onClick: () => menuClickHandler("devices"),
        },
      ];
    }

    return machinesDropdownMenu;
    //eslint-disable-next-line
  }, [currentUser]);

  const locationsDropdownMenu = useMemo(() => {
    let locationsDropdownMenu = [];
    if (hasAbility("can_view_location", currentUser.role.abilities)) {
      locationsDropdownMenu = [
        ...locationsDropdownMenu,
        {
          icon: <PlaceOutlined color="secondary" />,
          text: "Locations",
          path: "/locations",
          onClick: () => menuClickHandler("locations"),
        },
      ];
    }
    if (hasAbility("can_manage_spare_part", currentUser.role.abilities)) {
      locationsDropdownMenu = [
        ...locationsDropdownMenu,
        {
          icon: <WarehouseOutlined color="warning" />,
          text: "Part Warehouses",
          path: "/operate-warehouses",
          onClick: () => menuClickHandler("operate-warehouses"),
        },
      ];
    }

    return locationsDropdownMenu;
    //eslint-disable-next-line
  }, [currentUser]);

  const reportsDropdownMenu = useMemo(() => {
    let reportsDropdownMenu = [];
    if (hasAbility("can_view_service_history", currentUser.role.abilities)) {
      reportsDropdownMenu = [
        ...reportsDropdownMenu,
        {
          icon: <PlaceOutlined color="secondary" />,
          text: "Service History",
          path: "/service-history",
          onClick: () => menuClickHandler("service-history"),
        },
      ];
    }
    if (hasAbility("can_manage_machine", currentUser.role.abilities)) {
      reportsDropdownMenu = [
        ...reportsDropdownMenu,
        {
          icon: <GamesOutlined color="primary" />,
          text: "Machine Changes",
          path: "/machine-changes",
          onClick: () => menuClickHandler("machine-changes"),
        },
      ];
    }

    return reportsDropdownMenu;
    //eslint-disable-next-line
  }, [currentUser]);

  return (
    <Drawer
      sx={{
        width: isMobile ? "100%" : 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isMobile ? "100%" : 240,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <Box sx={{ width: 1, textAlign: "center", pl: 6, pt: 1 }}>
          <img src="logo512.png" alt="Logo" height="40" />
        </Box>
        <IconButton onClick={closeDrawerHandler}>
          <ChevronLeft />
        </IconButton>
      </DrawerHeader>
      <List sx={{ pt: 0 }}>
        <CustomDrawerItem
          text="Dashboard"
          icon={<DashboardOutlined color="primary" />}
          onClick={() => menuClickHandler("dashboard")}
          selected={isActive("/dashboard")}
        />
        {hasAbility("can_manage_service", currentUser.role.abilities) && (
          <CustomDrawerItem
            text="Service Manager"
            icon={<HomeRepairService color="info" />}
            onClick={() => menuClickHandler("service-manager")}
            selected={isActive("/service-manager")}
          />
        )}
        {/* <CustomDrawerItem
          text="Task Manager"
          icon={<FormatListNumbered color="success" />}
        />
        <CustomDrawerItem
          text="Reminder Manager"
          icon={<AccessAlarm color="warning" />}
        /> */}
      </List>
      <Divider sx={{ width: "90%", mx: "auto" }} />
      <List sx={{ pb: 0 }}>
        {machinesDropdownMenu.length > 0 && (
          <CustomDrawerItem
            text="Machines"
            icon={<GamesOutlined color="primary" />}
            onClick={(e) => {
              e.stopPropagation();
              setMachinesSubmenuOpen((prev) => !prev);
            }}
            additional={
              machinesSubmenuOpen ? (
                <ExpandLessOutlined />
              ) : (
                <ExpandMoreOutlined />
              )
            }
          />
        )}
        <Collapse in={machinesSubmenuOpen} timeout="auto" unmountOnExit>
          <List component="div" sx={{ padding: 0, paddingLeft: 2 }}>
            {machinesDropdownMenu.map((item, index) => (
              <CustomDrawerItem
                key={index}
                icon={item.icon}
                text={item.text}
                onClick={item.onClick}
                selected={isActive(item.path)}
              />
            ))}
          </List>
        </Collapse>
      </List>
      <List sx={{ pt: 0 }}>
        {locationsDropdownMenu.length > 0 && (
          <CustomDrawerItem
            text="Locations"
            icon={<PlaceOutlined color="secondary" />}
            onClick={(e) => {
              e.stopPropagation();
              setLocationsSubmenuOpen((prev) => !prev);
            }}
            additional={
              locationsSubmenuOpen ? (
                <ExpandLessOutlined />
              ) : (
                <ExpandMoreOutlined />
              )
            }
          />
        )}
        <Collapse in={locationsSubmenuOpen} timeout="auto" unmountOnExit>
          <List component="div" sx={{ padding: 0, paddingLeft: 2 }}>
            {locationsDropdownMenu.map((item, index) => (
              <CustomDrawerItem
                key={index}
                icon={item.icon}
                text={item.text}
                onClick={item.onClick}
                selected={isActive(item.path)}
              />
            ))}
          </List>
        </Collapse>
      </List>
      {(locationsDropdownMenu.length > 0 ||
        machinesDropdownMenu.length > 0) && (
        <Divider sx={{ width: "90%", mx: "auto" }} />
      )}

      <List>
        {reportsDropdownMenu.length > 0 && (
          <CustomDrawerItem
            text="Reports"
            icon={<AssessmentOutlined color="warning" />}
            onClick={(e) => {
              e.stopPropagation();
              setReportsSubmenuOpen((prev) => !prev);
            }}
            additional={
              reportsSubmenuOpen ? (
                <ExpandLessOutlined />
              ) : (
                <ExpandMoreOutlined />
              )
            }
          />
        )}
        <Collapse in={reportsSubmenuOpen} timeout="auto" unmountOnExit>
          <List component="div" sx={{ padding: 0, paddingLeft: 2 }}>
            {reportsDropdownMenu.map((item, index) => (
              <CustomDrawerItem
                key={index}
                icon={item.icon}
                text={item.text}
                onClick={item.onClick}
                selected={isActive(item.path)}
              />
            ))}
          </List>
        </Collapse>
      </List>
      {reportsDropdownMenu.length > 0 && (
        <Divider sx={{ width: "90%", mx: "auto" }} />
      )}
    </Drawer>
  );
};

export default SideBar;
