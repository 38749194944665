import { useState, useEffect } from "react";
import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useDevices } from "../../context/device-context";
import { useModal } from "../../context/modal-context";

const DevicesFilter = (props) => {
  const { modalState } = useModal();
  const {
    devicesLoading,
    searchDevices,
    deviceTypes,
    deviceTypesLoading,
    loadDeviceTypes,
  } = useDevices();
  const [selectedDeviceType, setSelectedDeviceType] = useState("all");

  const deviceTypeChangeHandler = (value) => {
    setSelectedDeviceType(value);
  };

  const onSearchHandler = () => {
    const searchObj = {
      device_type_id: selectedDeviceType !== "all" ? selectedDeviceType : null,
    };
    searchDevices(searchObj);
  };

  useEffect(() => {
    loadDeviceTypes();
    // eslint-disable-next-line
  }, []);

  const overallLoading =
    (devicesLoading || deviceTypesLoading) && !modalState.open;

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <Autocomplete
            id="device_type"
            options={[{ id: "all", name: "All" }, ...deviceTypes]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", name: "All" }, ...deviceTypes].find(
                (deviceType) => deviceType.id === selectedDeviceType
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: deviceTypes.length + 1 < 5,
                }}
                label="Device Type *"
              />
            )}
            getOptionLabel={(option) => option.show_name ?? option.name}
            onChange={(event, newOption) =>
              deviceTypeChangeHandler(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          variant="contained"
          sx={{ width: "100%", height: "100%" }}
          startIcon={!overallLoading && <SearchOutlined />}
          onClick={onSearchHandler}
          disabled={overallLoading}
        >
          {overallLoading ? <CircularProgress size={25} /> : "Search"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DevicesFilter;
