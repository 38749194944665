import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useCabinets } from "../../context/cabinet-context";
import { useCompanies } from "../../context/company-context";
import { useLocations } from "../../context/location-context";
import { useMachines } from "../../context/machine-context";
import { useModal } from "../../context/modal-context";
import { useParts } from "../../context/part-context";
import { MachineTypes } from "./MachineTypes";

const MachinesFilter = forwardRef((props, ref) => {
  const { modalState } = useModal();
  const [queryParams] = useSearchParams();
  const { searchMachines, loading: machinesLoading } = useMachines();
  const { companies, loading: companiesLoading } = useCompanies();
  const { locations, locationsLoading } = useLocations();
  const { cabinets, loading: cabinetsLoading } = useCabinets();
  const { parts, partsLoading } = useParts();

  const [selectedCompany, setSelectedCompany] = useState("all");
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [machineNumber, setMachineNumber] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [metrologyId, setMetrologyId] = useState("");
  const [selectedOwnership, setSelectedOwnership] = useState("all");
  const [selectedCabinet, setSelectedCabinet] = useState("all");
  const [selectedGame, setSelectedGame] = useState("all");
  const [selectedVersion, setSelectedVersion] = useState("all");
  const [selectedKernel, setSelectedKernel] = useState("all");
  const [selectedType, setSelectedType] = useState("All");

  useImperativeHandle(ref, () => ({
    resetFilter: () => {
      setSelectedCompany("all");
      setSelectedLocation("all");
      setMachineNumber("");
      setSerialNumber("");
      setMetrologyId("");
      setSelectedOwnership("all");
      setSelectedCabinet("all");
      setSelectedGame("all");
      setSelectedVersion("all");
      setSelectedKernel("all");
      setSelectedType("All");
    },
  }));

  useEffect(() => {
    if (queryParams.get("cid") && cabinets.length > 0) {
      setSelectedCabinet(+queryParams.get("cid"));
      onSearchHandler();
    }
    // eslint-disable-next-line
  }, [cabinets.length]);

  const onSearchHandler = () => {
    const searchObj = {
      company_id: selectedCompany !== "all" ? selectedCompany : null,
      location_id: selectedLocation !== "all" ? selectedLocation : null,
      machine_number: machineNumber.trim().length > 0 ? machineNumber : null,
      serial_number: serialNumber.trim().length > 0 ? serialNumber : null,
      metrology_id: metrologyId.trim().length > 0 ? metrologyId : null,
      ownership_id: selectedOwnership !== "all" ? selectedOwnership : null,
      cabinet_id: selectedCabinet !== "all" ? selectedCabinet : null,
      game: selectedGame !== "all" ? selectedGame : null,
      version: selectedVersion !== "all" ? selectedVersion : null,
      kernel: selectedKernel !== "all" ? selectedKernel : null,
      type: selectedType !== "All" ? selectedType : null,
    };
    searchMachines(searchObj);
  };

  const overallLoading =
    (companiesLoading ||
      locationsLoading ||
      partsLoading ||
      machinesLoading ||
      cabinetsLoading) &&
    !modalState.open;

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={6} sm={4} lg={2}>
        <FormControl fullWidth>
          <Autocomplete
            id="types"
            options={["All", ...MachineTypes]}
            value={selectedType}
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: MachineTypes.length + 1 < 5,
                }}
                label="Type"
              />
            )}
            onChange={(event, newOption) => setSelectedType(newOption ?? null)}
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FormControl fullWidth>
          <Autocomplete
            id="company"
            options={[{ id: "all", groupation_name: "All" }, ...companies]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", groupation_name: "All" }, ...companies].find(
                (company) => company.id === selectedCompany
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: companies.length + 1 < 5,
                }}
                label="Company"
              />
            )}
            getOptionLabel={(option) => option.groupation_name}
            onChange={(event, newOption) =>
              setSelectedCompany(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FormControl fullWidth>
          <Autocomplete
            id="locations"
            options={[{ id: "all", name: "All" }, ...locations]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", name: "All" }, ...locations].find(
                (location) => location.id === selectedLocation
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: locations.length + 1 < 5,
                }}
                label="Location"
              />
            )}
            getOptionLabel={(option) => option.show_name ?? option.name}
            onChange={(event, newOption) =>
              setSelectedLocation(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <TextField
          fullWidth
          id="machine_number"
          label="Machine Number"
          name="machine_number"
          value={machineNumber}
          onChange={(e) => setMachineNumber(e.target.value)}
          disabled={overallLoading}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <TextField
          fullWidth
          id="serial_number"
          label="Serial Number"
          name="serial_number"
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
          disabled={overallLoading}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <TextField
          fullWidth
          id="metrology_id"
          label="Metrology ID"
          name="metrology_id"
          value={metrologyId}
          onChange={(e) => setMetrologyId(e.target.value)}
          disabled={overallLoading}
        />
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FormControl fullWidth>
          <Autocomplete
            id="ownership"
            options={[{ id: "all", groupation_name: "All" }, ...companies]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", groupation_name: "All" }, ...companies].find(
                (company) => company.id === selectedOwnership
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: companies.length + 1 < 5,
                }}
                label="Ownership"
              />
            )}
            getOptionLabel={(option) => option.groupation_name}
            onChange={(event, newOption) =>
              setSelectedOwnership(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FormControl fullWidth>
          <Autocomplete
            id="cabinets"
            options={[{ id: "all", name: "All" }, ...cabinets]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", name: "All" }, ...cabinets].find(
                (cabinet) => cabinet.id === selectedCabinet
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: cabinets.length + 1 < 5,
                }}
                label="Cabinet"
              />
            )}
            getOptionLabel={(option) =>
              `${option.name} ${
                option.id !== "all" ? `(${option.manufacturer.show_name})` : ""
              }`
            }
            onChange={(event, newOption) =>
              setSelectedCabinet(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FormControl fullWidth>
          <Autocomplete
            id="game"
            options={[
              { id: "all", name: "All" },
              ...parts.filter((part) => part.part_type.name === "Game"),
            ]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [
                { id: "all", name: "All" },
                ...parts.filter((part) => part.part_type.name === "Game"),
              ].find((game) => game.id === selectedGame) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly:
                    parts.filter((part) => part.part_type.name === "Game")
                      .length +
                      1 <
                    5,
                }}
                label="Game"
              />
            )}
            getOptionLabel={(option) => option.show_name ?? option.name}
            onChange={(event, newOption) =>
              setSelectedGame(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FormControl fullWidth>
          <Autocomplete
            id="version"
            options={[
              { id: "all", name: "All" },
              ...parts.filter((part) => part.part_type.name === "Version"),
            ]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [
                { id: "all", name: "All" },
                ...parts.filter((part) => part.part_type.name === "Version"),
              ].find((version) => version.id === selectedVersion) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly:
                    parts.filter((part) => part.part_type.name === "Version")
                      .length +
                      1 <
                    5,
                }}
                label="Version"
              />
            )}
            getOptionLabel={(option) => option.show_name ?? option.name}
            onChange={(event, newOption) =>
              setSelectedVersion(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={4} lg={2}>
        <FormControl fullWidth>
          <Autocomplete
            id="kernel"
            options={[
              { id: "all", name: "All" },
              ...parts.filter((part) => part.part_type.name === "Kernel"),
            ]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [
                { id: "all", name: "All" },
                ...parts.filter((part) => part.part_type.name === "Kernel"),
              ].find((kernel) => kernel.id === selectedKernel) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly:
                    parts.filter((part) => part.part_type.name === "Kernel")
                      .length +
                      1 <
                    5,
                }}
                label="Kernel"
              />
            )}
            getOptionLabel={(option) => option.show_name ?? option.name}
            onChange={(event, newOption) =>
              setSelectedKernel(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4} lg={2}>
        <Button
          variant="contained"
          sx={{ width: "100%", height: "100%" }}
          startIcon={!overallLoading && <SearchOutlined />}
          onClick={onSearchHandler}
          disabled={overallLoading}
        >
          {overallLoading ? <CircularProgress size={25} /> : "Search"}
        </Button>
      </Grid>
    </Grid>
  );
});

export default MachinesFilter;
