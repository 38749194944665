import { useState } from "react";
import { Box, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Public } from "@mui/icons-material";
import { useAuth } from "../../../context/auth-context";


const CountryMenu = () => {
  const { currentUser, selectedCountry, changeCountry } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton onClick={handleClick}>
          <Public fontSize="large" sx={{ color: "white" }} />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 4,
          sx: {
            overflow: "visible",
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ p: 1, px: 2 }}>
          <Typography variant="body1" component="div">
            Current country:
            <Typography component="span" sx={{ fontWeight: "bold", ml: 0.5 }}>
              {selectedCountry.show_name}
            </Typography>
          </Typography>
        </Box>

        {currentUser.countries.length > 1 &&
          <Divider sx={{ mb: 1 }} />
        }

        {currentUser.countries.filter(country => country.show_name !== selectedCountry.show_name).map((country, index) => (
          <MenuItem key={index} onClick={() => changeCountry(country.id)}>
            <Typography variant="body1">{country.show_name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default CountryMenu
