import { useState, useEffect } from "react";
import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import { useParts } from "../../context/part-context";
import { useModal } from "../../context/modal-context";

const PartsFilter = (props) => {
  const { modalState } = useModal();
  const {
    partsLoading,
    searchParts,
    partTypes,
    partTypesLoading,
    loadPartTypes,
  } = useParts();
  const [selectedPartType, setSelectedPartType] = useState("all");

  const partTypeChangeHandler = (value) => {
    setSelectedPartType(value);
  };

  const onSearchHandler = () => {
    const searchObj = {
      part_type_id: selectedPartType !== "all" ? selectedPartType : null,
    };
    searchParts(searchObj);
  };

  useEffect(() => {
    loadPartTypes();
    // eslint-disable-next-line
  }, []);

  const overallLoading = (partTypesLoading || partsLoading) && !modalState.open;

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <Autocomplete
            id="part_type"
            options={[{ id: "all", name: "All" }, ...partTypes]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
              [{ id: "all", name: "All" }, ...partTypes].find(
                (partType) => partType.id === selectedPartType
              ) || null
            }
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                inputProps={{
                  ...inputProps,
                  readOnly: partTypes.length + 1 < 5,
                }}
                label="Part Type *"
              />
            )}
            getOptionLabel={(option) => option.show_name ?? option.name}
            onChange={(event, newOption) =>
              partTypeChangeHandler(newOption?.id ?? null)
            }
            disableClearable
            disabled={overallLoading}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          variant="contained"
          sx={{ width: "100%", height: "100%" }}
          startIcon={!overallLoading && <SearchOutlined />}
          onClick={onSearchHandler}
          disabled={overallLoading}
        >
          {overallLoading ? <CircularProgress size={25} /> : "Search"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PartsFilter;
