import {
  AddModeratorOutlined,
  CheckOutlined,
  ClearOutlined,
  Close,
  MilitaryTech,
  SecurityOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  DialogContentText,
  FormControl,
  Grid,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";
import { useModal } from "../../context/modal-context";
import { useRoles } from "../../context/role-context";
import useInput from "../../hooks/use-input";
import { getTitleForModal, isInRoleTypes } from "../../services/helpers";
import NTSModal from "../UI/NTSModal";
import NTSModalFooter from "../UI/NTSModalFooter";
import NTSMultiSelect from "../UI/NTSMultiSelect";

const RoleModal = () => {
  const { modalState, closeModal } = useModal();
  const { currentUser } = useAuth();
  const [assignedAbilities, setAssignedAbilities] = useState([]);
  const {
    roles,
    roleTypes,
    abilities,
    roleTypesLoading,
    abilitiesLoading,
    loadAbilities,
    loadRoleTypes,
    addRole,
    editRole,
    removeRole,
  } = useRoles();

  const {
    value: roleTypeValue,
    isValid: roleTypeIsValid,
    hasError: roleTypeHasError,
    valueBlurHandler: roleTypeBlurHandler,
    valueResetHandler: roleTypeResetHandler,
    setValueHandler: setRoleTypeHandler,
  } = useInput((value) => value !== "");
  const {
    value: roleNameValue,
    isValid: roleNameIsValid,
    hasError: roleNameHasError,
    valueChangeHandler: roleNameChangeHandler,
    valueBlurHandler: roleNameBlurHandler,
    valueResetHandler: roleNameResetHandler,
    setValueHandler: setRoleNameHandler,
  } = useInput((value) => value.trim().length > 3);
  const {
    value: roleLevelValue,
    isValid: roleLevelIsValid,
    hasError: roleLevelHasError,
    valueChangeHandler: roleLevelChangeHandler,
    valueBlurHandler: roleLevelBlurHandler,
    valueResetHandler: roleLevelResetHandler,
    setValueHandler: setRoleLevelHandler,
  } = useInput(
    (value) =>
      (value <= 100 && value > 0) ||
      (value !== "" &&
        isInRoleTypes(currentUser.role.role_type.name, ["admin"]))
  );

  const resetForm = useCallback(() => {
    roleNameResetHandler();
    roleTypeResetHandler();
    roleLevelResetHandler();
    setAssignedAbilities([]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    resetForm();
    if (!modalState.type || modalState.model !== "role") return;
    loadRoleTypes();
    loadAbilities();
    if (modalState.type === "add") return;

    const role = roles.find((role) => role.id === modalState.id);
    setRoleNameHandler(role?.show_name);
    setRoleTypeHandler(role?.role_type.id);
    setRoleLevelHandler(role?.level);
    setAssignedAbilities(role?.abilities);
    // eslint-disable-next-line
  }, [modalState]);

  const toggleAllAbilitiesHandler = () => {
    setAssignedAbilities((prevState) =>
      prevState.length > 0 ? [] : abilities
    );
  };

  const submitHandler = (event) => {
    event.preventDefault();
    closeModal();
    const roleObj = {
      show_name: roleNameValue,
      role_type_id: roleTypeValue,
      level: roleLevelValue,
    };
    const abilityIdsObject = {
      abilityIds: assignedAbilities.map((ability) => ability.id),
    };
    if (modalState.type === "add") {
      addRole(roleObj, abilityIdsObject);
    }
    if (modalState.type === "edit") {
      editRole(modalState.id, roleObj, abilityIdsObject);
    }
    if (modalState.type === "delete") {
      removeRole(modalState.id);
    }
    resetForm();
  };

  let title = "";
  let buttons = (
    <>
      <Button onClick={closeModal} endIcon={<ClearOutlined />} color="error">
        Cancel
      </Button>
      <Button
        onClick={submitHandler}
        disabled={!roleNameIsValid || !roleTypeIsValid || !roleLevelIsValid}
        endIcon={<CheckOutlined />}
        color="success"
      >
        Submit
      </Button>
    </>
  );

  if (modalState.open && modalState.type) {
    title = getTitleForModal(modalState.type);
  }
  if (modalState.type === "view") {
    buttons = (
      <Button onClick={closeModal} endIcon={<Close />} color="primary">
        Close
      </Button>
    );
  }

  return (
    modalState.open &&
    modalState.model === "role" && (
      <NTSModal
        title={`${title} ROLE`}
        maxWidth="md"
        loading={roleTypesLoading || abilitiesLoading}
        loadingSx={{ marginBottom: 5 }}
      >
        {modalState.type === "delete" ? (
          <DialogContentText>{`Are you sure that you want to remove role ${roleNameValue}?`}</DialogContentText>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  id="role_type"
                  options={roleTypes}
                  value={
                    roleTypes.find((type) => type.id === roleTypeValue) || null
                  }
                  renderInput={({ inputProps, ...params }) => (
                    <TextField
                      {...params}
                      error={roleTypeHasError}
                      inputProps={{
                        ...inputProps,
                        readOnly: roleTypes.length < 5,
                      }}
                      label="Role Type *"
                    />
                  )}
                  getOptionLabel={(option) => option.show_name ?? option.name}
                  onChange={(event, newOption) =>
                    setRoleTypeHandler(newOption?.id ?? null)
                  }
                  onClose={roleTypeBlurHandler}
                  disabled={modalState.type === "view"}
                  required
                  disableClearable
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Role name"
                name="name"
                disabled={modalState.type === "view"}
                value={roleNameValue}
                onChange={roleNameChangeHandler}
                onBlur={roleNameBlurHandler}
                error={roleNameHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SecurityOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="number"
                id="level"
                label="Level"
                name="level"
                disabled={modalState.type === "view"}
                value={roleLevelValue}
                onChange={roleLevelChangeHandler}
                onBlur={roleLevelBlurHandler}
                error={roleLevelHasError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AddModeratorOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <NTSMultiSelect
                color="success"
                loading={abilitiesLoading}
                assigns={assignedAbilities}
                onChange={setAssignedAbilities}
                values={abilities}
                icon={<MilitaryTech />}
                title="Abilities"
                disabled={modalState.type === "view"}
                noLimit={true}
              />
              {modalState.type !== "view" && (
                <Link
                  underline="hover"
                  variant="body2"
                  href="#"
                  onClick={toggleAllAbilitiesHandler}
                >
                  {assignedAbilities.length > 0
                    ? "Remove all abilities from role"
                    : "Assign all abilities to role"}
                </Link>
              )}
            </Grid>
          </Grid>
        )}
        <NTSModalFooter
          sx={{
            display: "flex",
            justifyContent: "end",
            marginY: 2,
          }}
        >
          {buttons}
        </NTSModalFooter>
      </NTSModal>
    )
  );
};

export default RoleModal;
