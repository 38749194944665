import {
  CardMedia,
  Divider,
  Link,
  ListItem,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useModal } from "../../../context/modal-context";
import {
  getColorForHoursPassed,
  getReadableDateWithHours,
} from "../../../services/helpers";
import { MachineTypes } from "../../Machine/MachineTypes";

const Paragraph = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "5px",
}));

const TextArea = styled(Typography)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const DetailsTabContent = (props) => {
  const theme = useTheme();
  const { service } = props;
  const { openModal } = useModal();

  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      alignItems="center"
      sx={{ paddingBottom: "10px" }}
    >
      {MachineTypes.includes(service.machine_type) && (
        <CardMedia
          component="img"
          sx={{ width: "151px", maxHeight: "280px", objectFit: "contain" }}
          image={`${process.env.REACT_APP_IMAGES_URL}/cabinets/${service.machine.cabinet.picture}`}
          alt="Cabinet"
        />
      )}
      <Stack direction="column" sx={{ flex: 1, width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingRight: "16px" }}
        >
          <ListItem>
            <Paragraph variant="body1">
              <Link
                component="button"
                sx={{ p: 0, m: 0, fontWeight: 600 }}
                underline="none"
                variant="body1"
                onClick={() =>
                  openModal("view", "location", service.location.id)
                }
              >
                {service.location.name} - {service.location.city}
              </Link>
            </Paragraph>
          </ListItem>
          {!!service.urgent && (
            <Typography
              variant="body1"
              sx={{ color: theme.palette.error.main }}
            >
              URGENT
            </Typography>
          )}
        </Stack>
        <ListItem>
          <Paragraph variant="body1">
            {MachineTypes.includes(service.machine_type) ? (
              <Link
                component="button"
                underline="none"
                variant="body1"
                sx={{ fontWeight: 600 }}
                onClick={() => openModal("view", "machine", service.machine.id)}
              >
                {service.machine.machine_number} -{" "}
                {service.machine.cabinet.name} [{service.machine.serial_number}]
                ({service.machine_type})
              </Link>
            ) : (
              `${service.machine_type} Service`
            )}
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph variant="body1">
            Requested by:
            <Link
              component="button"
              underline="none"
              variant="body1"
              sx={{ fontWeight: 600 }}
              onClick={() => openModal("view", "user", service.request_user.id)}
            >
              {service.request_user.fname} {service.request_user.lname} (
              {service.request_user.role.show_name})
            </Link>
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph variant="body1">
            Requested at:{" "}
            <span style={{ fontWeight: 600 }}>
              {getReadableDateWithHours(service.created_at)}
            </span>
          </Paragraph>
        </ListItem>
        <Divider />
        <ListItem>
          <TextArea variant="body1">
            Service Description:
            <span style={{ color: "red", fontSize: "17px", fontWeight: "600" }}>
              {service.error_description}
            </span>
          </TextArea>
        </ListItem>
        <Divider />
        <ListItem>
          <Paragraph variant="body1">
            Status:
            <span
              style={{ color: theme.palette.primary.main, fontWeight: "600" }}
            >
              {service.step}
            </span>
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph variant="body1">
            On current status:{" "}
            <span
              style={{
                color:
                  theme.palette[`${getColorForHoursPassed(service.updated_at)}`]
                    .main,
                fontWeight: "600",
              }}
            >
              {dayjs(service.updated_at).fromNow(true)}
            </span>
          </Paragraph>
        </ListItem>
        <ListItem>
          <Paragraph variant="body1">
            Overall time:
            <span
              style={{
                color:
                  theme.palette[`${getColorForHoursPassed(service.created_at)}`]
                    .main,
                fontWeight: "600",
              }}
            >
              {dayjs(service.created_at).fromNow(true)}
            </span>
          </Paragraph>
        </ListItem>
      </Stack>
    </Stack>
  );
};

export default DetailsTabContent;
