import { Alert, Container } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import AllServices from "../components/Service/AllServices";
import ServiceModal from "../components/Service/ServiceModal";
import ServicesFilter from "../components/Service/ServicesFilter";
import CRUDHeader from "../components/UI/CRUDHeader";
import LinearLoad from "../components/UI/LinearLoad";
import { useModal } from "../context/modal-context";
import { useServices } from "../context/service-context";
import { getHourFromDatetime } from "../services/helpers";

const Services = () => {
  const { openModal } = useModal();
  const [queryParams] = useSearchParams();
  const servicesFilterRef = useRef();
  const { services, loadActiveServices, loading, clearServicesData } =
    useServices();
  const [servicesForDisplay, setServicesForDisplay] = useState([]);

  useEffect(() => {
    clearServicesData();
    loadActiveServices();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setServicesForDisplay(services);
  }, [services]);

  const onSearchHandler = (filterConfig) => {
    let _services = [...services];

    Object.entries(filterConfig).map(([fieldName, fieldValue]) => {
      if (fieldValue !== "All") {
        switch (fieldName) {
          case "location":
            _services = _services.filter(
              (item) => item.location.name === fieldValue
            );
            break;
          case "step":
            _services = _services.filter((item) => item.step === fieldValue);
            break;
          case "type":
            _services = _services.filter(
              (item) => item.machine_type === fieldValue
            );
            break;
          case "time":
            switch (fieldValue) {
              case "Within 4 hours":
                _services = _services.filter(
                  (service) => getHourFromDatetime(service.created_at) <= 4
                );
                break;
              case "Within 4 to 12 hours":
                _services = _services.filter(
                  (service) =>
                    getHourFromDatetime(service.created_at) > 4 &&
                    getHourFromDatetime(service.created_at) <= 12
                );
                break;
              case "More than 12 hours":
                _services = _services.filter(
                  (service) => getHourFromDatetime(service.created_at) > 12
                );
                break;
              default:
                return _services;
            }
            break;
          default:
            return _services;
        }
      }
      return true;
    });

    setServicesForDisplay(_services);
  };

  useEffect(() => {
    if (services.length > 0) {
      onSearchHandler({
        location: queryParams.get("loc")?.replaceAll("_", " ") ?? "All",
        step: queryParams.get("prog")?.replaceAll("_", " ") ?? "All",
        type: queryParams.get("type")?.replaceAll("_", " ") ?? "All",
        time: queryParams.get("time")?.replaceAll("_", " ") ?? "All",
      });
    }
    // eslint-disable-next-line
  }, [services]);

  return (
    <>
      <Container maxWidth="xl" sx={{ paddingBottom: "16px" }}>
        <CRUDHeader
          title="Service Manager"
          onAddNew={() => openModal("add", "service")}
          onRefresh={() => {
            loadActiveServices();
            servicesFilterRef.current?.resetFilter();
          }}
        />
        {services.length < 1 ? (
          <>
            {!loading && (
              <Alert variant="outlined" severity="success">
                There are no active services right now
              </Alert>
            )}
            {loading && <LinearLoad sx={{ marginTop: "15px" }} />}
          </>
        ) : (
          <>
            <ServicesFilter
              ref={servicesFilterRef}
              onSearchHandler={onSearchHandler}
            />
            {loading && <LinearLoad sx={{ marginBottom: "15px" }} />}
            <AllServices servicesForDisplay={servicesForDisplay} />
          </>
        )}
      </Container>
      <ServiceModal />
    </>
  );
};

export default Services;
